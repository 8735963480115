import React from "react";
import NextHead from "next/head";

interface HeadProps {}

export const Head: React.FC<HeadProps> = (props) => {
  const title = "Exodia Galaxy Emblem Mint";
  const description =
    "3,000 Galaxy Emblem NFTs that provides access to the Exodia Alerts tool. Mint yours now!";

  return (
    <NextHead>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://exodia.io" />
      <meta
        property="og:image"
        content="https://mint.exodia.io/images/symbol.png"
      />
      <meta
        name="twitter:image"
        content="https://mint.exodia.io/images/symbol.png"
      />

      <link rel="icon" href="/favicon.ico" />
    </NextHead>
  );
};
