
import galaxy_emblem from "contracts/ExodiaFlowEmblem.json";

export enum ContractTypes {
  MINT_NFT_CONTRACT = "MINT_NFT_CONTRACT",
}

interface ContractData {
  [key: string]: {
    address: string;
    abi: any;
  };
}

export const contractsData: ContractData = {
  [ContractTypes.MINT_NFT_CONTRACT]: {
    address: process.env.NEXT_PUBLIC_CONTRACT_MINT_CONTRACT_ADDRESS || "",
    abi: galaxy_emblem.abi
  },
};

export const DEFAULT_MINT_PRICE = 0.01;
