import Button from "components/Button/Button";
import { useWeb3Context } from "components/Layout";
import React from "react";
import { ellipseAddress } from "utils/utilities";

interface ConnectButton {
  isHide?: boolean;
}

export default function ConnectButton({ isHide }: ConnectButton) {
  const { connect, disconnect, address } = useWeb3Context();

  return (
    <div className="mt-4 font-fredokaOne">
      {address ? (
        <div className="text-sm sm:text-lg">
          Connected: {ellipseAddress(address)}.{" "}
          <button onClick={disconnect} className="hover:underline">
            Switch
          </button>
        </div>
      ) : (
        !isHide && <Button onClick={connect}>CONNECT WALLET</Button>
      )}
    </div>
  );
}
