export enum T_SALE_STAGE {
  PRESALE = "PRESALE",
  CLOSED = "CLOSED",
  PUBLIC = "PUBLIC",
}

export enum T_NETWORKS {
  mainnet = "mainnet",
  rinkeby = "rinkeby",
  local = "local",
}

export enum T_CHAIN_IDS {
  mainnet = "1",
  rinkeby = "4",
  local = "1337",
}

export const INFURA_ID = process.env.NEXT_PUBLIC_INFURA_ID;

const NEXT_PUBLIC_VISIBLE_PAGE = process.env
  .NEXT_PUBLIC_VISIBLE_PAGE as T_SALE_STAGE;

const saleStageIsIncluded = Object.values(T_SALE_STAGE).includes(
  NEXT_PUBLIC_VISIBLE_PAGE
);

if (!saleStageIsIncluded) {
  throw new Error(
    `SALE STAGE: ${NEXT_PUBLIC_VISIBLE_PAGE} is not included in: ${Object.values(
      T_SALE_STAGE
    ).join(",")}`
  );
}

export const SALE_STAGE: T_SALE_STAGE = NEXT_PUBLIC_VISIBLE_PAGE;

const NEXT_PUBLIC_NETWORK = process.env.NEXT_PUBLIC_NETWORK as T_NETWORKS;

const networkIsIncluded =
  Object.values(T_NETWORKS).includes(NEXT_PUBLIC_NETWORK);

if (!networkIsIncluded) {
  throw new Error(
    `NETWORK: ${NEXT_PUBLIC_NETWORK} is not included in: ${Object.values(
      T_NETWORKS
    ).join(",")}`
  );
}

export const NETWORK: T_NETWORKS = NEXT_PUBLIC_NETWORK;

const NEXT_PUBLIC_CHAIN_ID = process.env.NEXT_PUBLIC_CHAIN_ID as T_CHAIN_IDS;

const chainIdIsIncluded =
  Object.values(T_CHAIN_IDS).includes(NEXT_PUBLIC_CHAIN_ID);

if (!chainIdIsIncluded) {
  throw new Error(
    `CHAIN ID: ${NEXT_PUBLIC_CHAIN_ID} is not included in: ${Object.values(
      T_CHAIN_IDS
    ).join(",")}`
  );
}

export const CHAIN_ID = Number(NEXT_PUBLIC_CHAIN_ID);

// @TODO: update this link
export const METAMASK_DEEPLINK = "https://metamask.app.link/dapp/#";

if (!Number(process.env.NEXT_PUBLIC_PRESALE_MAX_MINT)) {
  throw new Error("Please add NEXT_PUBLIC_PRESALE_MAX_MINT to process env");
}

export const MAX_MINT_PRESALE = Number(process.env.NEXT_PUBLIC_PRESALE_MAX_MINT);

if (!Number(process.env.NEXT_PUBLIC_PUBLIC_MAX_MINT)) {
  throw new Error("Please add NEXT_PUBLIC_PUBLIC_MAX_MINT to process env");
}

export const MAX_MINT_PUBLIC = Number(process.env.NEXT_PUBLIC_PUBLIC_MAX_MINT);

if (!Number(process.env.NEXT_PUBLIC_PRESALE_WEEK_NUMBER)) {
  throw new Error("Please add NEXT_PUBLIC_PRESALE_WEEK_NUMBER to process env");
}

export const NEXT_PUBLIC_PRESALE_WEEK_NUMBER = Number(
  process.env.NEXT_PUBLIC_PRESALE_WEEK_NUMBER
);
