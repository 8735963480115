import React, { createContext, useContext, useState } from "react";
import { Head } from "../components/Head";
import { useWeb3 } from "utils/web3";
import { IChainData } from "utils/types";
import Notification, {
  NotificationInterface,
  useNotification,
} from "components/Notification/Notification";
import Navbar from "components/Navbar/Navbar";
import Footer from "components/Footer/Footer";
import { NETWORK } from "utils/config";
import Container from "./Container/Container";
import Image from "next/image";

export type Web3Type = {
  connect: () => Promise<void>;
  disconnect: () => Promise<void>;
  chainData: IChainData | null;
  address?: string;
  web3Provider: any;
  isProcessing?: boolean;
  setIsProcessing: (
    isProcessing: boolean
  ) => React.Dispatch<React.SetStateAction<boolean>>;
  setNotification: (
    props: NotificationInterface
  ) => React.Dispatch<React.SetStateAction<NotificationInterface | undefined>>;
};

export const Web3Context = createContext<Web3Type | {}>({});

export const useWeb3Context = () => useContext(Web3Context) as Web3Type;

export const Layout: React.FC = (props) => {
  const { notification, setNotification, closeNotification } =
    useNotification();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const { connect, disconnect, chainData, address, web3Provider } =
    useWeb3(setNotification);

  return (
    <>
      <Head />

      <Web3Context.Provider
        value={{
          connect,
          disconnect,
          chainData,
          address,
          web3Provider,
          setNotification,
          isProcessing,
          setIsProcessing,
        }}
      >
        <div
          key={address}
          className="text-white min-h-screen flex flex-col items-center overflow-x-hidden z-10 font-fredokaOne pt-6 my-10"
        >
          <Navbar />

          <div className="flex w-[372px] h-[125px] relative">
            <Image layout="fill" src="/images/exodia-white.png" alt="" />
          </div>

          <Container>
            <div className="flex flex-col items-center justify-center text-white z-10">
              {chainData?.network === NETWORK || !chainData ? (
                props.children
              ) : (
                <h2 className="text-xl sm:text-4xl text-red-200 font-fredokaOne max-w-3xl text-center">
                  You are connected to the wrong network. Please switch to{" "}
                  {NETWORK} network.
                </h2>
              )}
            </div>
          </Container>
          <Footer />
        </div>
        <Notification notification={notification} onClose={closeNotification} />
      </Web3Context.Provider>
    </>
  );
};
