import { NotificationInterface } from "components/Notification/Notification";
import supportedChains from "./chains";
import { INFURA_ID, MAX_MINT_PUBLIC, SALE_STAGE, T_SALE_STAGE } from "./config";
import { IChainData } from "./types";

export function getChainData(chainId?: number): IChainData | null {
  if (!chainId) {
    return null;
  }
  const chainData = supportedChains.filter(
    (chain: any) => chain.chain_id === chainId
  )[0];

  if (!chainData) {
    throw new Error("ChainId missing or not supported");
  }

  const API_KEY = INFURA_ID;

  if (
    chainData.rpc_url.includes("infura.io") &&
    chainData.rpc_url.includes("%API_KEY%") &&
    API_KEY
  ) {
    const rpcUrl = chainData.rpc_url.replace("%API_KEY%", API_KEY);

    return {
      ...chainData,
      rpc_url: rpcUrl,
    };
  }

  return chainData;
}

export function ellipseAddress(address = "", width = 10): string {
  if (!address) {
    return "";
  }
  return `${address.slice(0, width)}...${address.slice(-width)}`;
}

export function commaFormat(value?: string) {
  if (value === undefined) return "N/A";
  return Number(value) >= 0 ? parseFloat(value).toLocaleString("en") : "N/A";
}

export function getUserError(message: string) {
  if (message.includes("MAX_MINT_AMOUNT")) {
    if (SALE_STAGE === T_SALE_STAGE.PUBLIC)
      return `Only ${MAX_MINT_PUBLIC} mints are allowed per address`;

    return `You don't have access to mint this number of NFTs`;
  }
  return "Reason: " + message.replace(/^(.*?)'/, "").replace("'", "");
}

export async function showTransactionNotifications(
  setNotification: (
    props: NotificationInterface
  ) => React.Dispatch<React.SetStateAction<NotificationInterface | undefined>>,
  setIsProcessing: (isProcessing: boolean) => React.Dispatch<React.SetStateAction<boolean>>,
  transaction: {
    wait: () => Promise<void>;
  }
) {
  try {
    setIsProcessing(true);
    setNotification({
      type: "success",
      title: "Success",
      message: "Transaction processing!",
    });
    await transaction.wait();
    setNotification({
      type: "success",
      title: "Success",
      message: "Transaction completed!",
    });
  } catch(error) {
    console.log(error);
  } finally {
    setIsProcessing(false);
  }
}

export const asyncFilter = async <T>(arr: Array<T>, predicate: any) => Promise.all(arr.map(predicate))
	.then((results) => arr.filter((_v: any, index: number) => results[index]));
