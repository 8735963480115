import React from "react";
import Image from "next/image";
import Link from "next/link";
import twitterIcon from "assets/images/twitter.svg";
import discordIcon from "assets/images/discord.svg";
import openSeaIcon from "assets/images/opensea.svg";
import { contractsData, ContractTypes } from "utils/contracts";

export default function Footer() {
  return (
    <footer className="text-center sm:p-16 py-8 flex flex-grow w-full m-0 z-1 flex-col justify-center items-center gap-8 font-rainshowCondensed">
      <div className="flex items-end flex-grow max-h-12">
        <a
          className="border p-3 flex items-center border-white border-opacity-50 border-r-0 rounded-l-3xl pl-4"
          href="https://discord.gg/exodia-io"
          rel="noreferrer noopener nofollow"
          target="_blank"
        >
          <Image
            className="hover:opacity-60 transition duration-150"
            src={discordIcon}
            alt=""
            width={24}
            height={24}
          />
        </a>

        <a
          className="border p-3 flex items-center border-white border-opacity-50 border-r-0"
          href="https://www.twitter.com/exodia_io"
          rel="noreferrer noopener nofollow"
          target="_blank"
        >
          <Image
            className="hover:opacity-60 transition duration-150"
            src={twitterIcon}
            alt=""
            width={24}
            height={24}
          />
        </a>
        
        <a
          className="border p-3 flex items-center border-white border-opacity-50 rounded-r-3xl pr-4"
          href="https://opensea.io/collection/exodia-galaxy" 
          rel="noreferrer noopener nofollow"
          target="_blank"
        >
          <Image
            className="hover:opacity-60 transition duration-150"
            src={openSeaIcon}
            alt=""
            width={24}
            height={24}
          />
        </a>
      </div>

      <div className="flex align-center flex-col justify-end items-center w-full">
        <div className="sm:ml-4 text-sm flex justify-end items-end flex-col leading-4 h sm:block">
          <div className="mt-2 w-full sm:w-auto">
            <Link
              href={`https://etherscan.io/address/${
                contractsData[ContractTypes.MINT_NFT_CONTRACT].address
              }#code`}
            >
              <a className="hover:underline">Smart Contract</a>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
