import "../styles/globals.scss";
import type { AppProps } from "next/app";
import { Layout } from "components/Layout";
import { useEffect } from "react";
import { logVisit } from "@sharemint/sdk";

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    logVisit();
  }, []);

  return (
    <Layout>
      <Component {...pageProps} />
    </Layout>
  );
}

export default MyApp;
